import React from 'react';
import { Select } from 'antd';
import styled from '@emotion/styled';

import tadaIcon from '../../../images/tada-20.svg';
import bookIcon from '../../../images/report-3.png';
import { handleRequestWhitepaperFormSubmit } from '../../../services/RequestFormService';
import * as S from './styles';
import { mediaQueries, color } from '../../variables';

export const ModalIcon = styled.i(({ iconUrl }) => ({
  display: 'block',
  position: 'absolute',
  backgroundImage: `url('${iconUrl}')`,
  width: '33rem',
  height: '30rem',
  margin: '0 0 3rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  [mediaQueries.xl]: {
    width: '29rem',
    height: '25rem',
  },
}));

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  researchNature: '',
  organizationName: '',
  showThanks: false,
};

class RequestWhitepaperFormModal extends React.Component {
  state = initialState;

  handleInputChange = event => this.setState({ [event.target.name]: event.target.value });

  handleSelectChange = researchNature => {
    this.setState({ researchNature });
  };

  toggleThanksModal = () => this.setState({ showThanks: !this.state.showThanks });

  handleSubmit = () => {
    event.preventDefault();
    handleRequestWhitepaperFormSubmit(this.state)
      .then(this.toggleThanksModal)
      .catch(alert);
  };

  handleModalClose = () => {
    this.props.onCancel();
    this.setState(initialState);
  };

  render() {
    const { onCancel, isVisible } = this.props;

    return (
      <S.StyledModal
        whitepaper={1}
        footer={false}
        closable={true}
        visible={isVisible}
        onCancel={onCancel}
      >
        {this.state.showThanks ? (
          <S.ContentRow whitepaper={1}>
            <S.ContentWrapper whitepaper={1}>
              <S.ModalIcon small iconUrl={tadaIcon} />
              <S.ModalTitle>Thank you!</S.ModalTitle>
              <S.ModalDescription center css={{ maxWidth: '37rem', margin: '0 auto 1rem' }}>
                We have received your request and a Modern Health representative will be in touch.
                Thank you for your interest.
              </S.ModalDescription>
              <S.ModalDescription center css={{ maxWidth: '48rem' }}>
                In the meantime, please feel free to check out our info sheet below. If you would
                like more information, you can always reach out to us at
                <a href="mailto:info@joinmodernhealth.com"> info@joinmodernhealth.com</a>
              </S.ModalDescription>
              <a
                href="https://s3-us-west-1.amazonaws.com/assets.joinmodernhealth.com/Modern_Health_3_Pager_Overview_April_2019.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                <S.ModalButton small onClick={this.handleModalClose}>
                  Get Info Sheet
                </S.ModalButton>
              </a>
            </S.ContentWrapper>
          </S.ContentRow>
        ) : (
          <S.ContentRow whitepaper={1}>
            <S.ContentColumn
              whitepaper={1}
              css={{ marginTop: '5rem', marginLeft: '2rem' }}
              md={24}
              lg={9}
            >
              <S.ModalTitle>Enter your contact information to get the white paper</S.ModalTitle>
              <form onSubmit={this.handleSubmit}>
                <S.InputWrapper>
                  <S.InputLabel>First Name *</S.InputLabel>
                  <S.StyledInput
                    type="text"
                    name="firstName"
                    placeholder="Jim"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Last Name *</S.InputLabel>
                  <S.StyledInput
                    type="text"
                    name="lastName"
                    placeholder="Rohn"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Your Email *</S.InputLabel>
                  <S.StyledInput
                    type="email"
                    name="email"
                    placeholder="jim.rohn@company.com"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>What is the nature of your research? *</S.InputLabel>
                  <S.StyledSelect
                    defaultValue="Please select"
                    onChange={this.handleSelectChange}
                    required
                  >
                    <Select.Option value="Just researching the space">
                      Just researching the space
                    </Select.Option>
                    <Select.Option
                      style={{ whiteSpace: 'inherit' }}
                      value="Currently tasked with finding a mental health benefit for my organization"
                    >
                      Currently tasked with finding a mental health benefit for my organization
                    </Select.Option>
                    <Select.Option value="A school project">A school project</Select.Option>
                    <Select.Option value="Personal interest">Personal interest</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                  </S.StyledSelect>
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.InputLabel>Organization name *</S.InputLabel>
                  <S.StyledInput
                    type="text"
                    name="organizationName"
                    placeholder="Company Ltd"
                    onChange={this.handleInputChange}
                    required
                  />
                </S.InputWrapper>
                <S.ButtonWrapper>
                  <S.ModalButton>Submit</S.ModalButton>
                </S.ButtonWrapper>
              </form>
            </S.ContentColumn>
            <S.ContentColumn
              withicon={1}
              css={{ paddingTop: '4rem', backgroundColor: color.cornflowerBlue }}
              lg={14}
            >
              <ModalIcon small iconUrl={bookIcon} />
              <S.ModalTitle css={{ color: 'white', marginTop: '27rem' }}>
                The Challenge
              </S.ModalTitle>
              <S.ModalDescription css={{ color: 'white', maxWidth: '38rem', opacity: '0.8' }} small>
                Stress, burnout, and poor emotional well-being are common in the workplace and are
                associated with high costs for employers and society including increased health care
                costs, decreased productivity, and high rates of absenteeism. The purpose of this
                white paper is to outline the role employers play in emotional well-being, the
                critical target areas, and promising models to solving this challenge.
              </S.ModalDescription>
            </S.ContentColumn>
          </S.ContentRow>
        )}
      </S.StyledModal>
    );
  }
}

export default RequestWhitepaperFormModal;
