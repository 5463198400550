import React from 'react';
import styled from '@emotion/styled';
import { Col } from 'antd';

import { mediaQueries, color } from '../../variables';

const CardWrapper = styled(Col)`
  margin: 1rem;
  justify-content: center;
  display: flex;
  ${mediaQueries.sm} {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem;
  }
`;

const CardContainer = styled.div(({ vertical }) => ({
  maxWidth: '570px',
  borderRadius: '0.5rem',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  padding: '3rem',
  textAlign: 'left',
  backgroundColor: color.white,
  '&:hover': {
    boxShadow: `3px 22px 71px -26px rgba(0, 0, 0, 0.36)`,
  },
  [mediaQueries.sm]: {
    flexDirection: vertical ? 'column' : 'row',
    boxShadow: '0 8px 18px 0 #dee7f2',
    padding: '0 0.25rem',
    border: 'none',
    maxWidth: '23rem',
  },
}));

const CardTitle = styled.h3(({ vertical }) => ({
  width: '100%',
  margin: '0',
  color: vertical ? color.bahamaBlue : color.midnight,
  paddingBottom: '1rem',
  fontSize: '20px',
}));

const CardContent = styled.p(({ vertical }) => ({
  color: color.manatee,
  fontSize: '18px',
  [mediaQueries.sm]: {
    fontSize: vertical ? '15px' : '16px',
    padding: '0 1rem 0 0',
  },
}));

const CardIcon = styled.i(({ iconUrl }) => ({
  marginRight: '0',
  marginLeft: 'auto',
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: '5rem',
  height: '1.5rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  [mediaQueries.md]: {
    margin: '1rem 0',
  },
}));

const CardContentWrapper = styled.div(({ vertical }) => ({
  [mediaQueries.sm]: {
    textAlign: vertical ? 'center' : 'left',
    padding: vertical ? '1rem 1rem' : '0.5rem 1rem',
  },
}));

const EmployeeCard = ({ title, icon, description, vertical }) => (
  <CardWrapper vertical={vertical} sm={20} md={20}>
    <CardContainer vertical={vertical}>
      <CardContentWrapper vertical={vertical}>
        <CardIcon vertical={vertical} iconUrl={icon} />
        <CardTitle vertical={vertical}>{title}</CardTitle>
        <CardContent vertical={vertical}>{description}</CardContent>
      </CardContentWrapper>
    </CardContainer>
  </CardWrapper>
);

export default EmployeeCard;
