import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import leftCircleIcon from '../../images/bg-circle-left.svg';
import rightCircleIcon from '../../images/bg-circle-right.svg';
import workIcon from '../../images/icon-failed-work.svg';
import honeyIcon from '../../images/icon-honeycombs.svg';
import calendarIcon from '../../images/icon-baby-calendar.svg';
import sneakersIcon from '../../images/icon-sneakers.svg';
import walletIcon from '../../images/icon-card-wallet.svg';
import lotusIcon from '../../images/icon-lotus.svg';
import trustIcon from '../../images/icon-trust.svg';
import headacheIcon from '../../images/icon-headache.svg';
import { mediaQueries, color } from '../variables';
import Card from '../shared/components/Card';
import * as S from '../shared/components/styles';
import backgroundDesktop from '../../images/bg.svg';
import backgroundMobile from '../../images/mask.svg';
import CompaniesContainer from './components/CompaniesContainer';

const experiences = [
  {
    icon: workIcon,
    title: 'Work Performance',
    description: 'I’m scared to speak up during work meetings.',
  },
  {
    icon: headacheIcon,
    title: 'Stress & Anxiety',
    description: 'Even small tasks feel daunting.',
  },
  {
    icon: sneakersIcon,
    title: 'Healthy Lifestyles',
    description: 'It’s rare for me to get a restful night of sleep.',
  },
  {
    icon: walletIcon,
    title: 'Financial Well-Being',
    description: 'My student loan debt seems insurmountable.',
  },
  {
    icon: honeyIcon,
    title: 'Diversity & Inclusion',
    description: 'I want to be a better ally but don’t know how.',
  },
  {
    icon: calendarIcon,
    title: 'Life Challenges',
    description: "I’m a first time parent and learning how to balance my life",
  },
  {
    icon: lotusIcon,
    title: 'Mindfulness & Meditation',
    description: 'I wish I could learn to enjoy the present moment.',
  },
  {
    icon: trustIcon,
    title: 'Relationships',
    description: 'I love my significant other, but I can’t get through to them.',
  },
];

const ContentRow = styled(Row)`
  width: 100%;
  padding: 2rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const CardsWrapper = styled(Row)`
  padding: 0 9rem;
  display: flex;
  justify-content: center;
  ${mediaQueries.xxl} {
    padding: 0 5rem;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const SectionWrapper = styled(Col)`
  position: relative;
  z-index: 5;
  padding: 1rem 1rem 14rem;
  width: 100%;
  ${mediaQueries.md} {
    padding: 1rem 0.5rem 7rem;
  }
  ${mediaQueries.sm} {
    padding: 1rem 0.5rem;
  }
`;

const SectionBackgroundIcon = styled.i(({ desktopIcon, mobileIcon }) => ({
  zIndex: 2,
  position: 'absolute',
  backgroundImage: `url('${desktopIcon}')`,
  width: '100%',
  height: '78rem',
  margin: '0 auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
  [mediaQueries.lg]: {
    height: '65rem',
  },
  [mediaQueries.md]: {
    backgroundImage: `url('${mobileIcon}')`,
    height: '100rem',
  },
  [mediaQueries.sm]: {
    backgroundImage: `url('${mobileIcon}')`,
    height: '85rem',
  },
  [mediaQueries.xs]: {
    height: '95rem',
  },
}));

const SectionIcon = styled.i(({ iconUrl, leftIcon }) => ({
  zIndex: 3,
  display: 'block',
  position: leftIcon ? 'relative' : 'absolute',
  backgroundImage: `url('${iconUrl}')`,
  width: leftIcon ? '5rem' : '2rem',
  height: leftIcon ? '8rem' : '5rem',
  right: leftIcon ? 'auto' : 0,
  top: leftIcon && 0,
  backgroundRepeat: 'no-repeat',
  [mediaQueries.xxxxl]: {
    display: 'none',
  },
  [mediaQueries.md]: {
    display: 'block',
  },
}));

const CompaniesWrapper = styled.div`
  ${mediaQueries.lg} {
    display: none;
  }
`;

const WhatsYourExperienceSection = () => (
  <>
    <SectionBackgroundIcon desktopIcon={backgroundDesktop} mobileIcon={backgroundMobile} />
    <SectionWrapper>
      <CompaniesWrapper>
        <CompaniesContainer />
      </CompaniesWrapper>
      <ContentRow>
        <S.SectionHeading small style={{ color: color.lightBlue }}>Mental health impacts all of us</S.SectionHeading>
        <S.SectionHeading style={{ width: '50%', color: color.white }}>There is no one-size-fits-all solution for mental well-being</S.SectionHeading>
      </ContentRow>
      <CardsWrapper type="flex">
        {experiences.map(experience => (
          <Card
            horizontal={1}
            smallIcon
            smallContent
            key={experience.icon}
            icon={experience.icon}
            title={experience.title}
            description={experience.description}
          />
        ))}
      </CardsWrapper>
      <SectionIcon iconUrl={rightCircleIcon} />
    </SectionWrapper>
  </>
);

export default WhatsYourExperienceSection;

