import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';

import womenIcon from '../../images/illu-homepage-women.svg';
import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';
import RequestWhitepaperButtonWidget from '../shared/components/RequestWhitepaperButtonWidget';
import RequestDemoButtonWidget from '../shared/components/RequestDemoButtonWidget';

const ContentRow = styled(Row)`
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: space-between;
  
`;

const ContentColumn = styled(Col)`
  width: 50%;
  text-align: left;
  ${mediaQueries.lg} {
    text-align: center;
    width: 60%;
  }
  ${mediaQueries.md} {
    width: 80%;
  }
`;

const SectionIcon = styled.i(({ iconUrl }) => ({
  backgroundImage: `url('${iconUrl}')`,
  width: '25rem',
  height: '25rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  [mediaQueries.xxxl]: {
    width: '20rem',
    height: '20rem',
  },
  [mediaQueries.xxl]: {
    width: '18rem',
    height: '18rem',
  },
  [mediaQueries.xl]: {
    width: '18rem',
    height: '18rem',
  },
  [mediaQueries.lg]: {
    display: 'none',
  },
}));

const ButtonsWrapper = styled.div`
  ${mediaQueries.lg} {
    display: flex;
    justify-content: center;
  }
  ${mediaQueries.sm} {
    display: flex;
    flex-direction: column;
  }
`;

const WelcomeSection = () => (
  <ContentRow>
    <ContentColumn md={24} lg={18} xl={17}>
      <S.SectionWelcomeHeading small>
          Mental wellness that meets you wherever you are
      </S.SectionWelcomeHeading>
      <S.SectionDescription semiTranspatentOpacity css={{ color: color.manatee }}>
        Modern Health is the only comprehensive mental health platform that provides access to a full spectrum of resources, ranging from digital courses and meditations to a network of certified coaches and licensed therapists
      </S.SectionDescription>
      <ButtonsWrapper>
        <RequestDemoButtonWidget />
      </ButtonsWrapper>
    </ContentColumn>
    <SectionIcon iconUrl={womenIcon} />
  </ContentRow>
);

export default WelcomeSection;
