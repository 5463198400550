import React from 'react';
import styled from '@emotion/styled';
import { Row } from 'antd';

import firstSectionBackground from '../../images/bg-hero-section-2.svg';
import WelcomeSection from './WelcomeSection';
import { mediaQueries } from '../variables';
import CompaniesContainerMobile from './components/CompaniesContainerMobile';

const SectionWrapper = styled(Row)`
  padding: 18rem 18rem 6rem;
  &:last-of-type {
    padding-bottom: 4rem;
  }
  ${mediaQueries.xxxl} {
    padding: 18rem 8rem 2rem;
  }
  ${mediaQueries.xxl} {
    padding: 15rem 5rem 2rem;
  }
  ${mediaQueries.xl} {
    padding: 12rem 4rem 2rem;
  }
  ${mediaQueries.lg} {
    padding: 10rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 7rem 2rem 0;
  }
  ${mediaQueries.xs} {
    padding: 7rem 0.5rem 0;
  }
  background-image: url(${firstSectionBackground});
  background-size: cover;
`;

const CompaniesWrapper = styled.div`
  ${mediaQueries.xxxxl} {
    display: none;
  }
  ${mediaQueries.lg} {
    display: flex;
  }
`;

const WelcomeSectionWrapper = () => (
  <SectionWrapper>
    <WelcomeSection />
    <CompaniesWrapper>
      <CompaniesContainerMobile />
    </CompaniesWrapper>
  </SectionWrapper>
);

export default WelcomeSectionWrapper;
