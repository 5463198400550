import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { mediaQueries, color } from '../../variables';
import RequestWhitepaperFormModal from './RequestWhitepaperFormModal';
import { LabelButtonWrapper } from './styles';

const RequestWhitepaperBtn = styled(Button)`
  && {
    padding: 0;
    width: 13.5rem;
    height: 3.1rem;
    margin-top: 0.75rem;
    margin-right: 0.5rem;
    border-radius: 2rem;
    color: ${color.echoBlue};
    background-color: white;
    font-size: 1rem;
    border: solid 2.5px ${color.linkWater};
    outline: none;
    &:hover {
      background-color: #ededf1;
      border-color: ${color.linkWater};
    }
    ${mediaQueries.sm} {
      width: 20rem;
      margin: 0 auto;
    }
    ${mediaQueries.xs} {
      width: 18rem;
    }
  }
`;

class RequestWhitepaperButtonWidget extends React.Component {
  state = {
    isRequestDemoModalOpen: false,
    isRequestWhitepaperModalOpen: false,
  };

  toggleRequestDemoModal = () =>
    this.setState(state => ({ isRequestDemoModalOpen: !state.isRequestDemoModalOpen }));

  toggleRequestWhitepaperModal = () =>
    this.setState(state => ({
      isRequestWhitepaperModalOpen: !state.isRequestWhitepaperModalOpen,
    }));

  render() {
    return (
      <>
        <RequestWhitepaperBtn onClick={this.toggleRequestWhitepaperModal}>
          <LabelButtonWrapper>Request white paper</LabelButtonWrapper>
        </RequestWhitepaperBtn>
        <RequestWhitepaperFormModal
          isVisible={this.state.isRequestWhitepaperModalOpen}
          onCancel={this.toggleRequestWhitepaperModal}
        />
      </>
    );
  }
}

export default RequestWhitepaperButtonWidget;
