import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css';

import leftCircleIcon from '../../images/bg-circle-left.svg';
import rightCircleIcon from '../../images/bg-circle-right.svg';
import programs from '../../images/programs.svg';
import virtualCoaching from '../../images/virtualCoaching.svg';
import clinicalTherapy from '../../images/clinicalTherapy.svg';
import meditationSession from '../../images/meditation-2.png';
import videoSession from '../../images/chat-with-coach.png';
import messaging from '../../images/messaging.png';

import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';

const cards = [
  {
    title: 'Digital Programs',
    desc: 'Engaging evidence-based courses and mediation developed by clinical psychologists',
    svg: programs,
    image: meditationSession,
  },
  {
    title: 'Virtual Coaching',
    desc: 'Support from ICF-certified coaches through in-app video calls and live text-based messaging',
    svg: virtualCoaching,
    image: messaging,
  },
  {
    title: 'Clinical Therapy',
    desc: 'Evidence-based therapy from high-quality licensed clinicians through in-person visits or over video',
    svg: clinicalTherapy,
    image: videoSession,
  },
];

const SectionWrapper = styled(Col)`
  position: relative;
  z-index: 5;
  padding: 1rem 0rem 7rem;
  width: 100%;
  ${mediaQueries.md} {
    padding: 10rem 0.5rem 7rem;
  }
  ${mediaQueries.sm} {
    padding: 15rem 1rem 0rem 0rem;
  }
`;

const ContentRow = styled(Row)`
  width: 100%;
  padding: 10rem 0rem 0rem 2rem;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const HeaderWrapper = styled.div`
  margin: 0 8rem;
  ${mediaQueries.md} {
    margin: 0;
  }
`;

const CardsWrapper = styled(Row)`
  padding: 3rem 14rem 0rem;
  display: flex;
  justify-content: space-around;
  ${mediaQueries.xxxl} {
    padding: 3rem 5rem 0rem;
  }
  ${mediaQueries.xxl} {
    padding: 3rem 0;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
  ${mediaQueries.lg} {
  }
`;

const CardWrapper = styled(Col)`
  margin: 1rem;
  display: flex;
  justify-content: center;
`;



const CardContainer = styled.div`
  padding: 1rem 2rem;
  max-width: 288px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  // box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
  // background-color: ${color.white};
`;

const SectionIcon = styled.i(({ iconUrl, rightIcon }) => ({
  display: 'block',
  position: 'absolute',
  backgroundImage: `url('${iconUrl}')`,
  width: rightIcon ? '19rem' : '22rem',
  height: '45rem',
  right: rightIcon ? 0 : 'auto',
  backgroundRepeat: 'no-repeat',
  marginTop: rightIcon ? '16rem' : '0',
  [mediaQueries.xxl]: {
    width: rightIcon ? '10rem' : '22rem',
  },
  [mediaQueries.xl]: {
    width: rightIcon ? '3rem' : '22rem',
  },
  [mediaQueries.sm]: {
    display: 'none',
  },
}));

const Programs = styled.i(({ iconUrl }) => ({
  backgroundImage: `url('${iconUrl}')`,
  width: '3rem',
  height: '3rem',
  color: color.aliceBlue,
}));

const Image = styled.i(({ imageUrl }) => ({
  backgroundImage: `url('${imageUrl}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto',
}));

const CardHeading = styled.h3`
  font-size: 20px;
  color: ${color.bahamaBlue};
  font-weight: 700;
  margin-bottom: 0;
`;

const HowItWorks = () => (
  <SectionWrapper>
    <SectionIcon rightIcon iconUrl={rightCircleIcon} />
    <SectionIcon iconUrl={leftCircleIcon} />
    <ContentRow>
      <HeaderWrapper>
        <S.SectionHeading css={{ fontSize: '40px' }}>
          How it works
          </S.SectionHeading>
        <S.SectionSwipeHeading css={{ maxWidth: '56rem', fontSize: '22px', color: color.manatee }}>
          Modern Health combines technology and human touch to guide individuals to the right level of care at the right time. Each employee has access to a full spectrum of support in a single platform to meet their unique needs. 
        </S.SectionSwipeHeading>
      </HeaderWrapper>
    </ContentRow>
    <CardsWrapper type="flex">
      {cards.map(card => (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={card.image} style={{ marginTop: '15px', zIndex:8, width: '250px' }}/>
          <CardWrapper key={card.title}>
            <CardContainer>
              <CardHeading>{card.title}</CardHeading>
            </CardContainer>
          </CardWrapper>
          <S.SectionSwipeHeading css={{ maxWidth:'288px',zIndex:8,  fontSize: '18px', color: color.manatee, textAlign: 'center'}}>
            {card.desc}
          </S.SectionSwipeHeading>
        </div>
      ))}
    </CardsWrapper>
  </SectionWrapper>
);

export default HowItWorks;
