import React from 'react';
import styled from '@emotion/styled';
import { Col, Button } from 'antd';

import mobileBackground from '../../images/bg-cta.svg';
import { mediaQueries, color } from '../variables';
import RequestDemoFormModal from '../shared/components/RequestDemoFormModal';
import { RequestDemoButton } from '../shared/components/styles';

const SectionWrapper = styled(Col)`
  ${mediaQueries.xxxxl} {
    display: none;
  }
  ${mediaQueries.sm} {
    display: block;
    padding: 11rem 0;
    background-image: url(${mobileBackground});
    background-size: cover;
    background-position: center;
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  ${mediaQueries.xxxxl} {
    display: none;
  }
  ${mediaQueries.sm} {
    display: block;
  }
`;

const SectionHeading = styled.h2`
  color: ${color.white};
  margin: '0 0 1rem';
  font-size: 27px;
  font-weight: 500;
  padding-bottom: 1rem;
`;

class DiscoverBetterTomorrowSection extends React.Component {
  state = {
    isModalOpen: false,
  };

  toggleRequestDemoModal = () => this.setState(state => ({ isModalOpen: !state.isModalOpen }));

  render() {
    return (
      <>
        <SectionWrapper>
          <ContentWrapper>
            <SectionHeading>Take care of your people</SectionHeading>
            <RequestDemoButton onClick={this.toggleRequestDemoModal}>
              Request demo
            </RequestDemoButton>
          </ContentWrapper>
        </SectionWrapper>
        <RequestDemoFormModal
          isVisible={this.state.isModalOpen}
          onCancel={this.toggleRequestDemoModal}
        />
      </>
    );
  }
}

export default DiscoverBetterTomorrowSection;
