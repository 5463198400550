import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';

import forbesIcon from '../../../images/logo-forbes-2.svg';
import combinatorIcon from '../../../images/logo-ycombinator-2.svg';
import techCrunchIcon from '../../../images/logo-techcrunch-2.svg';
import cgntIcon from '../../../images/logo-cgtn-2.svg';
import wsjIcon from '../../../images/logo-wsj-2.svg';
import { mediaQueries, color } from '../../variables';

const ContentRow = styled(Row)`
  padding-left: 5rem;
  width: 100%;
  ${mediaQueries.lg} {
    padding: 0;
  }
  ${mediaQueries.sm} {
    margin-top: 2rem;
  }
`;

const ContentColumn = styled(Col)`
  padding-top: 0;
  display: flex;
  justify-content: center;
  ${mediaQueries.md} {
    justify-content: center;
  }
`;

const StyledParagraph = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: ${color.echoBlue};
  opacity: 0.5;
  padding-top: 2.6rem;
  margin: 0 auto;
`;

const StepIcon = styled('i')(({ iconUrl, width, small }) => ({
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: width,
  height: '7rem',
  margin: '0 auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  marginRight: '5rem',
  [mediaQueries.xl]: {
    marginRight: '3rem',
  },
  [mediaQueries.sm]: {
    marginLeft: '0.5rem',
    width: small && '9rem',
    marginRight: '0.5rem',
  },
  [mediaQueries.xs]: {
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
  },
}));

const CompaniesContainer = () => (
  <ContentRow>
    <ContentColumn md={24}>
      <StyledParagraph>As seen in:</StyledParagraph>
    </ContentColumn>
    <ContentColumn md={24} lg={7}>
      <a href="https://www.forbes.com/sites/harvardpilgrimhealthcare/2019/01/17/why-companies-are-supporting-emotional-wellness-in-the-workplace/#3cf90b167146 ">
        <StepIcon
          css={{
            [mediaQueries.xs]: {
              width: '5rem',
            },
          }}
          width={'7rem'}
          iconUrl={forbesIcon}
        />
      </a>
      <a href="https://blog.ycombinator.com/13-companies-from-yc-winter-2018/ ">
        <StepIcon width={'9rem'} iconUrl={combinatorIcon} />
      </a>
    </ContentColumn>
    <ContentColumn css={{ [mediaQueries.sm]: { justifyContent: 'center' } }} md={24} lg={12}>
      <a href="https://techcrunch.com/2018/06/15/yc-alum-modern-health-a-startup-focused-on-emotional-wellbeing-gets-2-26m-seed-funding/ ">
        <StepIcon small width={'13rem'} iconUrl={techCrunchIcon} />
      </a>
      <a href="https://america.cgtn.com/2018/10/04/tech-startup-brings-wellbeing-to-the-workplace">
        <StepIcon width={'6rem'} iconUrl={cgntIcon} />
      </a>
    </ContentColumn>
  </ContentRow>
);

export default CompaniesContainer;
