import React from 'react';
import styled from '@emotion/styled';
import { Row } from 'antd';

import { mediaQueries, color } from '../../variables';
import Card from '../../shared/components/Card';

const cards = [
  {
    title: 'Assess and Personalize',
    description:
      'Whether someone wants to proactively reduce stress or treat depression, we guide people to the right care at the right time.',
  },
  {
    title: 'Evidence-Based Digital Care',
    description:
      'Employees learn to be better coworkers and managers by improving their emotional intelligence and building resilience.',
  },
  {
    title: 'Professional Support',
    description:
      'Employees are matched to a coach or therapist based on their unique needs and preferences.',
  },
];

const CardsWrapper = styled(Row)`
  padding: 7rem 2rem;
  z-index: 5;
  display: flex;
  justify-content: center;
  text-align: left;
  ${mediaQueries.xxl} {
    padding: 5rem 5rem;
  }
  ${mediaQueries.xl} {
    padding: 3rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 3rem 0rem;
  }
`;

const VerticalCard = styled.div`
  max-width: 30rem;
  background-color: white;
  padding: 1rem;
  margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
    opacity: 1;
  ${mediaQueries.sm} {
    border-radius: 10px;
    box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
    opacity: 1;
  }
`;

const CardTitle = styled.h3`
  font-size: 22px;
  color: ${color.bahamaBlue};
`;
const CardContent = styled.p`
  font-size: 14px;
  color: ${color.manatee};
`;

const NavigationSupportCards = () => (
  <CardsWrapper type="flex">
    {cards.map(card => (
      <VerticalCard key={card.title}>
        <CardTitle>{card.title}</CardTitle>
        <CardContent>{card.description}</CardContent>
      </VerticalCard>
    ))}
  </CardsWrapper>
);

export default NavigationSupportCards;
