import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css';

import leftCircleIcon from '../../images/bg-circle-left.svg';
import rightCircleIcon from '../../images/bg-circle-right.svg';
import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';

const cards = [
  {
    title: 'Depression is the #1 cause of disability worldwide',
    description: 'Do we have to wait for it to get worse to take action?',
  },
  {
    title: 'EAP utilization  is less than 3%',
    description: 'Yet most won’t report their revenue',
  },
  {
    title: 'There’s a shortage of therapists',
    description: 'In California there are 20 therapists per 10k residents',
  },
  {
    title: 'Over 60% of therapists don’t take insurance',
    description: 'Because insurance companies only pay therapists $60 per visit ',
  },
];

const SectionWrapper = styled(Col)`
  position: relative;
  z-index: 5;
  padding: 1rem 0rem 10rem;
  width: 100%;
  ${mediaQueries.md} {
    padding: 10rem 0.5rem 7rem;
  }
  ${mediaQueries.sm} {
    padding: 15rem 1rem 0rem 0.5rem;
  }
`;

const ContentRow = styled(Row)`
  width: 100%;
  padding: 10rem 5rem 2rem;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const HeaderWrapper = styled.div`
  margin: 0 8rem;
  ${mediaQueries.md} {
    margin: 0;
  }
`;

const CardsWrapper = styled(Row)`
  padding: 3rem 14rem 0rem;
  display: flex;
  justify-content: center;
  ${mediaQueries.xxxl} {
    padding: 3rem 5rem 0rem;
  }
  ${mediaQueries.xxl} {
    padding: 3rem 0;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
  ${mediaQueries.lg} {
    display: none;
  }
`;

const CardWrapper = styled(Col)`
  margin: 1rem;
  display: block;
  ${mediaQueries.xl} {
    margin: 2rem;
  }
  ${mediaQueries.lg} {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
`;

const CardHeading = styled.h3`
  font-size: 24px;
  color: ${color.mariner};
  font-weight: 700;
`;

const CardDescription = styled.p`
  font-size: 16px;
  color: ${color.manatee};
`;

const CardContainer = styled.div`
  padding: 3rem 2rem;
  max-width: 288px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);

  background-color: ${color.white};
  ${mediaQueries.lg} {
    width: 288px;
    height: 300px;
  }
`;

const SwiperWrapper = styled.div`
  ${mediaQueries.xxxxl} {
    display: none;
  }
  ${mediaQueries.lg} {
    display: flex;

    width: 100%;
    .swiper-container {
      padding-bottom: 4rem;
    }
    .swiper-pagination-bullet-active {
      background: white;
    }
  }
`;

const SectionIcon = styled.i(({ iconUrl, rightIcon }) => ({
  display: 'block',
  position: 'absolute',
  backgroundImage: `url('${iconUrl}')`,
  width: rightIcon ? '19rem' : '22rem',
  height: '45rem',
  right: rightIcon ? 0 : 'auto',
  backgroundRepeat: 'no-repeat',
  marginTop: rightIcon ? '16rem' : '0',
  [mediaQueries.xxl]: {
    width: rightIcon ? '10rem' : '22rem',
  },
  [mediaQueries.xl]: {
    width: rightIcon ? '3rem' : '22rem',
  },
  [mediaQueries.sm]: {
    display: 'none',
  },
}));

const sliderSettings = {
  initialSlide: 0,
  loop: true,
  centeredSlides: true,
  speed: 800,
  simulateTouch: true,
  spaceBetween: 200,
  grabCursor: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
};

const MentalHealthImpactSection = () => (
  <SectionWrapper>
    <SectionIcon rightIcon iconUrl={rightCircleIcon} />
    <SectionIcon iconUrl={leftCircleIcon} />
    <CardsWrapper type="flex">
      {cards.map(card => (
        <CardWrapper key={card.title} md={20} lg={7} xl={5}>
          <CardContainer>
            <CardHeading>{card.title}</CardHeading>
            <CardDescription>{card.description}</CardDescription>
          </CardContainer>
        </CardWrapper>
      ))}
    </CardsWrapper>
    <SwiperWrapper>
      <Swiper {...sliderSettings}>
        {cards.map(card => (
          <CardWrapper key={card.title} lg={24}>
            <CardContainer>
              <CardHeading>{card.title}</CardHeading>
              <CardDescription>{card.description}</CardDescription>
            </CardContainer>
          </CardWrapper>
        ))}
      </Swiper>
    </SwiperWrapper>
  </SectionWrapper>
);

export default MentalHealthImpactSection;
