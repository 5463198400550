import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';

import mountainIcon from '../../images/illu-mountain2.svg';
import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';
import NavigationSupportCards from './components/NavigationSupportCards';
import leftCircleIcon from '../../images/bg-circle-left.svg';

const SectionWrapper = styled(Row)`
  position: relative;
  padding: 5rem 0rem 0rem 0rem;
  background-color: ${color.white};
  background-size: cover;
  z-index: 5;
`;

const ContentRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 5;

`;

const ContentColumn = styled(Col)`
  padding: 0 1rem;
`;

const SectionIcon = styled.i(({ iconUrl }) => ({
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: '36rem',
  height: '30rem',
  margin: '0 auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  marginTop: '8rem',
  [mediaQueries.xxl]: {
    width: '30rem',
  },
  [mediaQueries.lg]: {
    width: '30rem',
    marginTop: 0,
  },
  [mediaQueries.sm]: {
    width: '18rem',
    height: '16rem',
    marginTop: 0,
  },
  [mediaQueries.xs]: {
    width: '16rem',
  },
}));

const SectionIconCircle = styled.i(({ iconUrl, rightIcon }) => ({
  display: 'block',
  position: 'absolute',
  backgroundImage: `url('${iconUrl}')`,
  width: rightIcon ? '19rem' : '22rem',
  height: '45rem',
  right: rightIcon ? 0 : 'auto',
  backgroundRepeat: 'no-repeat',
  marginTop: rightIcon ? '16rem' : '0',
  [mediaQueries.xxl]: {
    width: rightIcon ? '10rem' : '22rem',
  },
  [mediaQueries.xl]: {
    width: rightIcon ? '3rem' : '22rem',
  },
  [mediaQueries.sm]: {
    display: 'none',
  },
}));

const NavigationSupportSection = () => (
  <SectionWrapper>
    <SectionIconCircle iconUrl={leftCircleIcon} />
    <ContentRow>
      <S.SectionHeading small></S.SectionHeading>
      <S.SectionHeading css={{ maxWidth: '46rem', margin: '0 auto' }}>
        How we do it
      </S.SectionHeading>
      <ContentColumn md={24} lg={12} xl={12}>
        <SectionIcon iconUrl={mountainIcon} />
      </ContentColumn>
      <ContentColumn md={24} lg={12} xl={12}>
        <NavigationSupportCards />
      </ContentColumn>
    </ContentRow>
  </SectionWrapper>
);

export default NavigationSupportSection;
