import React, { useState, useEffect } from "react";
import { Button, Icon } from "antd";
import styled from "@emotion/styled";
import { throttle } from "lodash";

import { color, mediaQueries } from "../variables";
import window from "../../utils/window";

const ModalContainer = styled.div(props => ({
  position: "relative",
  display: "flex",
  width: "100%",
  zIndex: 10,
  transition: ".6s all",
  top: props.visible ? 0 : -(520 - 3.9 * 16),
  backgroundColor: color.royalBlue
}));

const ModalContentContainer = styled.div(props => ({
  display: props.visible ? "flex" : "none",
  height: 75,
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  flexDirection: "row",

  [mediaQueries.sm]: {
    justifyContent: "space-between",
    height: 100
  }
}));

const ArticleTitle = styled.h3({
  color: color.white,
  textAlign: "center",
  fontWeight: "500",
  fontSize: "1.5rem",
  marginTop: 0,
  marginBottom: 0,
  [mediaQueries.lg]: {
    fontSize: "1rem",
    textAlign: "left",
    paddingLeft: 30,
    width: "85%"
  },
  [mediaQueries.md]: {
    fontSize: ".85rem",
    textAlign: "left",
    paddingLeft: 30,
    width: "85%"
  },
  [mediaQueries.sm]: {
    fontSize: "1rem",
    textAlign: "left",
    paddingLeft: 30,
    width: "85%"
  }
});

export const ArticleModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [shouldHideModal, setShouldHideModal] = useState(true);
  const [topOffset, setTopOffset] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = throttle(() => {
      setTopOffset(window.scrollY);
    }, 100);

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setModalVisible(true);
    }, 500);
  }, []);

  if (topOffset < 500 && !shouldHideModal) {
    setModalVisible(true);
    setShouldHideModal(true);
  }

  if (topOffset > 500 && shouldHideModal) {
    setShouldHideModal(false);
    setModalVisible(false);
  }

  return (
    <ModalContainer visible={modalVisible}>
      <ModalContentContainer visible={modalVisible}>
        <ArticleTitle>
          {`Modern Health is providing free Community Support Resources during COVID-19. `}
          <a
            href="https://community.modernhealth.com/"
            target="_blank"
            style={{
              color: "white",
              textDecoration: "underline"
            }}
          >
            See them here.
          </a>
        </ArticleTitle>
        <Icon
          onClick={() => setModalVisible(!modalVisible)}
          style={{
            position: "absolute",
            right: 30,
            fontSize: "1.75rem",
            color: "white",
            alignSelf: "center"
          }}
          type="close-circle"
        />
      </ModalContentContainer>
    </ModalContainer>
  );
};
