import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import WelcomeSectionWrapper from '../components/index/WelcomeSectionWrapper';
import NavigationSupportSection from '../components/index/NavigationSupportSection';
import HowItWorks from '../components/index/HowItWorks';
import WhatsYourExperienceSection from '../components/index/WhatsYourExperienceSection';
import DiscoverBetterTomorrowSection from '../components/index/DiscoverBetterTomorrowSection';
import YourEmployeesSection from '../components/index/YourEmployeesSection';
import WantToTakeCareSection from '../components/index/WantToTakeCareSection';
import FooterWrapper from '../components/index/FooterWrapper';
import MentalHealthImpactSection from '../components/index/MentalHealthImpact';
import { ArticleModal } from '../components/index/ArticleModal';
import ErrorBoundary from '../components/shared/components/ErrorBoundary';

const IndexPage = () => (
  <ErrorBoundary>
    <Layout>
      <ArticleModal />
      <Navigation />
      <WelcomeSectionWrapper />
      <WhatsYourExperienceSection />
      <HowItWorks />
      <DiscoverBetterTomorrowSection />
      <YourEmployeesSection />
      <FooterWrapper homepage={1}>
      <WantToTakeCareSection />
      </FooterWrapper>
    </Layout>
  </ErrorBoundary>
);

export default IndexPage;
