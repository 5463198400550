import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import Swiper from 'react-id-swiper';

import footerDesktopBackground from '../../images/bg-footer.svg';
import heartIcon from '../../images/heart.svg';
import arrowRightIcon from '../../images/arrow1.svg';
import arrowLeftIcon from '../../images/arrow2.svg';
import fiveStarsIcon from '../../images/icon-five-stars.svg';
import { mediaQueries, color } from '../variables';
import EmployeeCard from './components/EmployeeCard';
import * as S from '../shared/components/styles';
import EALogo from '../../images/logos/EALogo.png'
import gitlab from '../../images/logos/gitlab.png'
import gusto from '../../images/logos/gusto.png'
import Lambda from '../../images/logos/Lambda.png'
import Nextdoor from '../../images/logos/Nextdoor.png'
import Okta from '../../images/logos/Okta.png'
import Pixar from '../../images/logos/Pixar.png'

const logos = [
  { image: EALogo, width: '6rem' }, { image: gitlab, width: '9rem' }, 
  { image: gusto, width: '9rem' }, { image: Lambda, width: '11rem' },
  { image: Nextdoor, width: '14rem' }, { image: Okta, width: '7rem' }, 
  { image: Pixar, width: '14rem' }
]
const cards = [
  {
    icon: fiveStarsIcon,
    title: 'Changed my life',
    description: `”Overall, I feel that I have the tools I need to face life and I don't know that I would have found them without the help of Modern Health.”`,
  },
  {
    icon: fiveStarsIcon,
    title: 'Love it!',
    description: `“I'm a better manager at work,  I started a daily meditation practice, I'm more patient in relationships.”`,
  },
  {
    icon: fiveStarsIcon,
    title: 'My favorite benefit',
    description:
      '“I really appreciate what Modern Health has set out to do! I encourage everyone at my company to take advantage of this wonderful benefit, and I hope that more companies offer this to their employees.”',
  },
  {
    icon: fiveStarsIcon,
    title: 'Amazing!',
    description: `“Modern Health helped me improve evaluating my personal health and how that translates to my role and the work I'm doing”`,
  },
  {
    icon: fiveStarsIcon,
    title: 'Thank you!',
    description: `“I started a daily meditation practice. I was also connected with a therapist who perfectly matches my needs and have learned to navigate my daily stressors and work on my codependency.”`,
  },
  {
    icon: fiveStarsIcon,
    title: 'This is so exciting!',
    description: `“Especially given the recent suicides of celebrities and their reminder that our country struggles with mental health, I'm so happy to see us taking the bigger picture of emotional well-being.”`,
  },
];

const sliderSettings = {
  initialSlide: 0,
  slidesPerView: 'auto',
  loop: true,
  centeredSlides: true,
  speed: 400,
  simulateTouch: true,
  grabCursor: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoplay: {
    delay: 5000,
  },
};

const ContentRow = styled(Row)`
  width: 100%;
  padding: 2rem 5rem;
  display: block;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const CardsWrapper = styled(Row)`
  display: flex;
  justify-content: center;
  ${mediaQueries.xxxl} {
    padding: 0;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
  .swiper-container {
    padding-top: 1.35rem;
    padding-bottom: 3.75rem;
    margin: 0 10rem;
    ${mediaQueries.lg} {
      margin: 0;
    }
  }
  .swiper-pagination {
    position: inherit;
    top: 0;
    padding: 0 3rem;
    z-index: 0;
  }
  .swiper-pagination-bullet-active {
    background: white;
  }
  .swiper-button-next {
    background-image: url('${arrowRightIcon}');
    ${mediaQueries.md} {
      display: none;
    }
  }
  .swiper-button-prev {
    background-image: url('${arrowLeftIcon}');
    ${mediaQueries.md} {
      display: none;
    }
  }
`;

const SectionWrapper = styled(Col)`
  margin-top: 7rem;
  padding: 12rem 1rem 0;
  width: 100%;
  background-image: url(${footerDesktopBackground});
  background-size: cover;
  background-repeat: no-repeat;
  ${mediaQueries.sm} {
    background-image: linear-gradient(to top, ${color.solitudeLight}, ${color.white});
    margin-top: 4rem;
    padding-top: 3rem;
  }
`;

const SectionIcon = styled.i(({ iconUrl }) => ({
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: '2rem',
  height: '2rem',
  backgroundRepeat: 'no-repeat',
  margin: '1.2rem',
  [mediaQueries.md]: {
    margin: '0.5rem 1rem',
  },
  [mediaQueries.sm]: {
    position: 'absolute',
    margin: '6rem auto',
  },
}));

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoRow = styled(Row)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 5rem;
  width: 100%;
  ${mediaQueries.xl} {
    padding-left: 1rem;
  }
  ${mediaQueries.lg} {
    padding: 0;
  }
  ${mediaQueries.sm} {
    margin-top: 2rem;
    backgroundColor: ${color.white};
  }
`;

const StepIcon = styled('i')(({ iconUrl, width }) => ({
  backgroundImage: `url('${iconUrl}')`,
  height: '10rem',
  width: width,
  margin: '0 2rem',
  
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
}));

const StyledParagraph = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: ${color.aliceBlue};
  opacity: 0.5;
  padding-top: 2.6rem;
  margin: 0 auto;
  text-align: center;
  ${mediaQueries.sm}{
    color: ${color.bahamaBlue};
  }
`;


const YourEmployeesSection = () => (
  <SectionWrapper>

    <ContentRow>
      <HeaderWrapper>
        <S.SectionHeading
          css={{
            color: color.white,
            [mediaQueries.sm]: {
              color: color.bahamaBlue,
              paddingBottom: '3rem',
            },
          }}
        >
          People love it
        </S.SectionHeading>
        <SectionIcon iconUrl={heartIcon} />
      </HeaderWrapper>
    </ContentRow>
    <CardsWrapper type="flex">
      <Swiper {...sliderSettings}>
        {cards.map(card => (
          <div key={card.title}>
            <EmployeeCard icon={card.icon} title={card.title} description={card.description} />
          </div>
        ))}
      </Swiper>
    </CardsWrapper>
    <StyledParagraph style={{zIndex: 10}}>Trusted by companies like:</StyledParagraph>
    <LogoRow>
      {logos.map((logo, i) => <StepIcon key={'company-logos' + i} iconUrl={logo.image} width={logo.width} />)}
    </LogoRow>
  </SectionWrapper>
);

export default YourEmployeesSection;
