import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';

import forbesIcon from '../../../images/logo-forbes.svg';
import combinatorIcon from '../../../images/logo-ycombinator.svg';
import techCrunchIcon from '../../../images/logo-techcrunch.svg';
import cgntIcon from '../../../images/logo-cgtn.svg';
import wsjIcon from '../../../images/logo-wsj.svg';
import { mediaQueries, color } from '../../variables';

const ContentRow = styled(Row)`
  padding-left: 5rem;
  width: 100%;
  ${mediaQueries.xl} {
    padding-left: 1rem;
  }
  ${mediaQueries.lg} {
    padding: 0;
  }
  ${mediaQueries.sm} {
    margin-top: 2rem;
  }
`;

const ContentColumn = styled(Col)`
  padding-top: 0;
  display: flex !important;
`;

const StyledParagraph = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: ${color.aliceBlue};
  opacity: 0.5;
  padding-top: 2.6rem;
  margin: 0 auto;
`;

const StepIcon = styled('i')(({ iconUrl, width, small }) => ({
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: width,
  height: '7rem',
  margin: '0 auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  marginRight: '8rem',
  [mediaQueries.xxxl]: {
    marginRight: '8rem',
  },
  [mediaQueries.xxl]: {
    marginRight: '6rem',
  },
  [mediaQueries.xl]: {
    marginRight: '3rem',
  },
  [mediaQueries.lg]: {
    marginLeft: '7rem',
    marginRight: '1rem',
  },
  [mediaQueries.md]: {
    marginLeft: '4rem',
  },
  [mediaQueries.sm]: {
    marginLeft: '0.25rem',
    width: small && '9rem',
    marginRight: '0.5rem',
  },
}));

const CompaniesContainer = () => (
  <ContentRow>
    <ContentColumn lg={4} xl={3}>
      <StyledParagraph>As seen in:</StyledParagraph>
    </ContentColumn>
    <div css={{ display: 'flex', justifyContent: 'flex-start' }}>
      <ContentColumn
        lg={{ span: 5, offset: 2 }}
        xl={{ span: 6, offset: 2 }}
        xxl={{ span: 7, offset: 3 }}
      >
        <div><StepIcon width={'7rem'} iconUrl={forbesIcon} /></div>
        <div><StepIcon width={'9rem'} iconUrl={combinatorIcon} /></div>
      </ContentColumn>
      <ContentColumn
        lg={{ span: 6, offset: 5 }}
        xl={{ span: 6, offset: 3 }}
        xxl={{ span: 6, offset: 1 }}
      >
        <div><StepIcon small width={'13rem'} iconUrl={techCrunchIcon} /></div>
        <div><StepIcon width={'6rem'} iconUrl={cgntIcon} /></div>
      </ContentColumn>
    </div>
  </ContentRow>
);

export default CompaniesContainer;
